
.fs-80 {
    font-size: 0.8em !important;
}

.fs-90{
    font-size: 0.9em !important;
}

.text-dark-green{
    color:#146c43;
}

.cursor-pointer {
    cursor:pointer;
}