
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
  );
  
$blue: #0054a6;
$yellow: #fd7e14;
$form-floating-label-opacity:     .9;













@import "../../node_modules/bootstrap/scss/bootstrap.scss";



