$main-nav-horizontal-with:991px;
$register-page-horizontal-with: 575px;
$blue: #0054a6;
$red: #dc3545;



@import "./utility.scss";

body#dqp-body{
	margin:0; 
	padding:0; 
	color:#4B555A; 
	background:#efefef; 
	
	font:normal 16px/150% arial, verdana, sans-serif;

	/*
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	*/
}


#main-navbar{
    border:1px solid #ccc;
}

#main-navbar .nav-link:hover{
	background-color: #ddd;
}

#main-navbar .nav-link.active{
	background-color: $blue	;
	color: #fff;
}

.dropdown-menu li div.clear{
	display: none;
}

#user-profile {
	max-width: 200px;
}

@media (max-width: $main-nav-horizontal-with) {
	#user-profile {
		max-width: none;
	}

	#main-navbar .navbar-nav{
		padding-top:10px;
	}

	#main-navbar .navbar-nav .nav-link{
		padding-left:10px;
	}
}

.clickable-table tbody tr{
	cursor: pointer;
}

.hoverable .hide-not-hover {
	visibility: hidden;
}

.hoverable:hover .hide-not-hover {
	visibility: visible;
}

.btn-icon-16 {
	line-height: 16px;
	padding: 3px;
}

.pointer-cursor{
	cursor: pointer;
}
/** Begin : for registration page **/

#reg-divider {
    min-height: 300px;
    width: 0.8em;
    margin:20px auto;
    border-right:1px solid #d3d3d3;
}

#reg-divider span{
    display: inline-block;
    background-color: #fff;
    margin-top:100px;
    font-size: 0.8em;
}

@media (max-width: $register-page-horizontal-with) {
    #reg-divider{
        border-right: none;
        border-bottom:1px solid #d3d3d3;
        min-height:auto;
        width: auto;
    }
    #reg-divider span{
        margin-top: 0px;
        position: relative;
        top:1em;
    }
}

/** End    : for registration page **/


/* ----- video tutorial page ------*/
.video-col{
	/* height:700px; */
	overflow: auto;
	margin:0;
	padding:0;
	list-style: none;
}

.video-col li{
	padding:5px 8px;
	cursor:pointer;
	border:1px solid #fff;
}

.video-col li:hover{
	border:1px solid #eee;
}

.video-col li.selected{
	background-color:#eee;
}
.video-col li img{
	float:left;
	width:50%;
	margin-right:10px;
}

@media (min-width: 425px) {
	.video-col{
		height:auto;
	}

	.video-col li img{
		width:30%;
	}
}

/**** react components ***********/

.react-select-invalid > div{
	border-color:$red;
}

.react-select-invalid ~ .invalid-feedback{
	display: block;
}


.react-datepicker-block .react-datepicker-wrapper{
	display: block;
}

.react-datepicker-block.react-date-invalid .invalid-feedback{
	display: block;
}

.webmap {
	/* height: 320px; */
	height: 320px;
}


/*** temporary for mockup ***/
.select-border-info .css-yk16xz-control {
	border:1px solid #0dcaf0 !important;
	
}